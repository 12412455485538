<template>
  <VuePerfectScrollbar class="e-content__inner e-content__inner--wide">
    <div class="e-content__scroll">
      <div class="e-module e-module--limited">
        <Loading v-if="isLoading" />

        <header
          v-if="content.title && !isLoading"
          class="e-module__head"
        >
          <h1>{{ content.title }}</h1>
        </header>
        <div
          v-if="content.body && !isLoading"
          class="e-module__body"
          v-html="content.body"
        />
      </div>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import api from 'api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Loading from 'atoms/Loading/Loading'

export default {
  components: {
    Loading,
    VuePerfectScrollbar
  },
  computed: {
    ...mapGetters(['language', 'pageDataLoading']),
    isLoading () {
      return this.loading || this.pageDataLoading
    }
  },
  data: () => ({
    content: {},
    loading: false
  }),

  watch: {
    $route: function () {
      this.getContent()
    },
    language: function (newVal, oldVal) {
      if (newVal && oldVal) {
        this.getContent()
      }
    }
  },
  created () {
    this.getContent()
  },
  methods: {
    getContent: async function () {
      this.loading = true
      this.content = {}

      try {
        const response = await api.call('getContent', this.$route.params.contentName, { locale: this.language })
        this.content = {
          title: this.$t(response.data.title),
          body: response.data.body
        }
      } catch (error) {
        this.$error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.e-loading {
  display: block;
  margin: 0 auto;
}
.e-overall .ps {
  overflow: auto !important;
  position: absolute;
  bottom: 0;
  top: 0;
  @include break(m) {
    overflow: hidden !important;
    @at-root .mobile {
      overflow: auto !important;
    }
  }
}
</style>
